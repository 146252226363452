import DispensaryProductPage from 'src/dispensary/product';
import { DispensaryProductLayout } from 'src/components/layouts/dispensary-product-layout';
import { getStaticPaths } from 'utils/ssr';
import { generateConsumerDispensaryStaticProps } from 'src/dispensary/utils/ssr/generate-consumer-dispensary-static-props';

export { getStaticPaths };
export const getStaticProps = async (context) => generateConsumerDispensaryStaticProps(context);

DispensaryProductPage.layout = DispensaryProductLayout;
export default DispensaryProductPage;
