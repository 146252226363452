import React from 'react';

import DispensaryHeader from 'src/dispensary/components/dispensary-header';
import GlobalFooter from 'components/global-footer-rebrand';
import LayoutWithHeader from 'components/layouts/with-header';
import { DesktopOnly, MobileOnly } from 'shared/components';

import useDispensary from 'src/dispensary/hooks/use-dispensary';

import { LayoutWrapper } from './layout-wrapper';

export function DispensaryProductLayout({ children }) {
  const { dispensary } = useDispensary();

  if (!dispensary) {
    return null;
  }

  return (
    <LayoutWrapper>
      <DesktopOnly display='block'>
        <LayoutWithHeader>
          <DispensaryHeader />
          {children}
        </LayoutWithHeader>
      </DesktopOnly>
      <MobileOnly display='block'>
        <LayoutWithHeader>{children}</LayoutWithHeader>
        <GlobalFooter />
      </MobileOnly>
    </LayoutWrapper>
  );
}
