/* eslint-disable react/react-in-jsx-scope */
import usePaths from 'hooks/use-paths';
import Link from 'next/link';
import { BottomText, Container, Image, StyledLink, TextWrapper, TopText } from './out-of-stock-message.styles';

type OutOfStockMessageProps = {
  dispensary: {
    id: string;
    cName: string;
  };
};

const OutOfStockMessage = ({ dispensary }: OutOfStockMessageProps): JSX.Element => {
  const { href } = usePaths({ dispensary });
  return (
    <Container>
      <Image alt='' src='/images/out-of-stock-product.png' />
      <TextWrapper>
        <TopText>This item is no longer in stock</TopText>
        <BottomText>Please try searching for another item.</BottomText>
        <Link href={href} passHref>
          <StyledLink>Home</StyledLink>
        </Link>
      </TextWrapper>
    </Container>
  );
};

export default OutOfStockMessage;
