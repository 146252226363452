/* eslint-disable react/react-in-jsx-scope */
import EmptyLoadingState from 'components/empty-loading-state';
import _ from 'lodash';
import { useObserver } from 'mobx-react';
import { useEffect, useMemo } from 'react';

import Product from 'components/product';
import BackToMenu from 'components/product/back-to-menu';
import { Container } from 'components/product/layout';
import { CategoryOptions } from 'shared/constants';
import { OftenPurchasedWithCarousel, RelatedItemsCarousel } from 'src/components/carousels';
import { DispensaryProductLayout } from 'src/components/layouts/dispensary-product-layout';

import useProduct from 'hooks/use-product';
import moment from 'moment';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import useStores from 'shared/hooks/use-stores';
import { WhoopsContent, WhoopsReason } from 'src/components/whoops';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import { getEventDescription } from 'src/utils/helpers/product';
import { isBrowser } from 'utils/misc-utils';
import OutOfStockMessage from '../../components/out-of-stock-message';

export default function DispensaryProductPage() {
  const { dispensary } = useDispensary();
  const { product, loading, archivedProductData, archivedProductDataLoading } = useProduct();
  const archivedProduct = archivedProductData?.archivedProductLookup[0];
  const { UI } = useStores();
  const showOutOfStockNotification = _.get(dispensary, 'storeSettings.provideBackInstockEmailNotifications', false);
  const productPosition = useObserver(() => UI.productPosition);
  const carouselLabels = useObserver(() => UI.carouselLabels);
  const category = useMemo(() => (product ? _.find(CategoryOptions, { value: product.type }) : {}), [product]);
  const amplitude = useAmplitude();

  const isArchivedProductOlderThanOneYear =
    moment(archivedProduct?.updatedAt).isBefore(moment().subtract(1, 'years')) || false;

  useEffect(() => {
    if (!_.isEmpty(category?.value) && !_.isEmpty(dispensary?.id) && !_.isEmpty(product?.id)) {
      const eventDescription = getEventDescription(productPosition, carouselLabels, {
        categoryName: category.value,
        dispensaryId: dispensary.id,
        dispensaryName: dispensary?.name,
        productId: product.id,
        productName: product?.name,
        menuVariant: UI.analyticsMenuVariant,
      });
      amplitude.log(AmplitudeEvents.menu.viewProduct, eventDescription);
    }
    // rationale: exhaustive deps cause these events to be fired more than we want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  if (!dispensary) {
    return null;
  }

  if (!loading && !product && !archivedProductData?.archivedProductLookup?.length && !archivedProductDataLoading) {
    // we finished loading but still don't have a product
    // effectively a 404
    if (isBrowser()) {
      return <WhoopsContent reason={WhoopsReason.pageNotFound} />;
    }
  }

  if (isBrowser() && archivedProduct && isArchivedProductOlderThanOneYear && showOutOfStockNotification) {
    return <OutOfStockMessage dispensary={dispensary} />;
  }

  return (
    <Container key={product?.id}>
      <BackToMenu category={category} />
      <EmptyLoadingState isLoading={loading || archivedProductDataLoading} page='product'>
        {(product || archivedProduct) && <Product product={product || archivedProduct} dispensary={dispensary} />}
      </EmptyLoadingState>

      <RelatedItemsCarousel productId={product?.id} category={product?.type} />
      {product && <OftenPurchasedWithCarousel productId={product.id} category={product.type} />}
    </Container>
  );
}

DispensaryProductPage.layout = DispensaryProductLayout;
