import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.5rem;
  justify-content: center;
  padding: 2.5rem 0;
`;

export const Image = styled.img`
  width: 11.625rem;
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  justify-content: center;
`;

export const TopText = styled.p`
  color: #2c3236;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.875rem; /* 125% */
`;

export const BottomText = styled.p`
  color: #828a8f;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
`;

export const StyledLink = styled.a`
  background: #3387e1;
  border: none;
  border-radius: 62.5rem;
  color: #ffffff;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.005rem;
  line-height: 1.25rem; /* 125% */
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-transform: uppercase;

  &:hover {
    background: #1d548e;
    cursor: pointer;
  }
`;
