import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';

import ChevronIcon from 'assets/chevron-icon';
import { Clickable } from 'shared/components';

import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useDispensary from 'src/dispensary/hooks/use-dispensary';

export default function BackToMenu() {
  const router = useRouter();
  const { dispensary } = useDispensary();
  const UI = useUI();
  const { t } = useTranslation();

  const handleClick = _.once(() => {
    if (UI.externalReferrer) {
      if (dispensary) {
        UI.showMenu(dispensary.cName);
      } else {
        UI.showHome();
      }
    } else {
      router.back();
      UI.externalReferrer = true;
    }
  });

  return (
    <Back role='link' onClick={handleClick}>
      <BackArrow />
      {t('common.back', 'Back')}
    </Back>
  );
}

const BackArrow = styled(ChevronIcon)`
  fill: ${({ theme }) => theme.customized.colors.buttonsLinks};
  transform: rotate(90deg);
  height: 8px;
  margin-right: 5px;
`;

const Back = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: ${({ theme }) => theme.customized.textTransforms.links};
`;
